import React from 'react'
import Fade from 'embla-carousel-fade'
import Autoplay from 'embla-carousel-autoplay'

import { Arrow } from '@/assets/icons/Arrow'
import { cn } from '@/lib/utils'
import type { Post, ProductNode } from '@/api'
import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/react/atoms/carousel/carousel'

interface CardProps {
  post: Post | ProductNode
  fullWidth?: boolean
  callToAction?: string
  borderColor?: boolean
}

interface TravelGuideGridProps {
  sectionTitle: string
  posts: { data: Post }[]
}

const Card: React.FC<CardProps> = ({ post, fullWidth, callToAction, borderColor }) => {
  return (
    <a href={post.uri} className="group">
      <article
        className={cn(
          'relative rounded-4xl overflow-hidden group-hover:shadow-xl transition-all duration-300',
          fullWidth ? 'aspect-[4/3] md:aspect-[16/9]' : 'aspect-[4/3]',
          borderColor && 'group-hover:border-pink border-transparent border-[5px]'
        )}
      >
        <img
          className="object-cover w-full h-full"
          src={post.featuredImage.node.sourceUrl}
          srcSet={post.featuredImage.node.srcSet}
          alt={post.featuredImage.node.altText || post.title}
          width={2000}
          height={1500}
          sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, (max-width: 1200px) 1200px, (max-width: 1600px) 1600px, 2000px"
        />

        {'categories' in post && post.categories && (
          <div className="absolute top-8 bg-white rounded-r-lg">
            <p className="px-2.5 py-1 text-blue font-black text-base">
              {post.categories.nodes[0].name}
            </p>
          </div>
        )}
        <div
          className={cn(
            'w-full pt-20 absolute bottom-0 left-0 text-white px-6 bg-gradient-to-t from-neutral-900 via-[#000000a7] to-transparent transition-all duration-300 ease-out',
            fullWidth
              ? 'flex flex-col md:flex-row justify-between items-end pb-16 md:pb-14'
              : 'translate-y-8 group-hover:translate-y-0 '
          )}
        >
          <h3
            className={cn(
              ' font-black',
              fullWidth ? 'text-2xl lg:text-5xl' : 'text-lg lg:text-3xl'
            )}
          >
            {post.title}
          </h3>
          {callToAction && (
            <div
              className={cn(
                fullWidth &&
                  'min-w-full md:min-w-[150px] flex flex-col md:flex-row justify-start md:justify-end '
              )}
            >
              <p
                className={cn(
                  'font-bold items-center flex transition-all duration-300 ease-out mt-2',
                  fullWidth
                    ? 'text-xl lg:text-2xl'
                    : 'opacity-0 translate-y-2 group-hover:opacity-100 group-hover:translate-y-0'
                )}
              >
                {callToAction}

                <span className="ml-2 mt-1 text-pink">
                  <Arrow />
                </span>
              </p>
            </div>
          )}
        </div>
      </article>
    </a>
  )
}

const TravelGuideGrid: React.FC<TravelGuideGridProps> = ({ sectionTitle, posts }) => {
  const featuredPosts = posts.slice(0, 4)
  const remainingPosts = posts.slice(4)

  return (
    <div className="flex flex-col gap-8 md:gap-14 mt-12 md:mt-36 container">
      <h2 className="text-center">{sectionTitle}</h2>

      <div>
        {featuredPosts.length > 0 && (
          <Carousel
            plugins={[Fade(), Autoplay({ playOnInit: true, delay: 5000 })]}
            className="group"
          >
            <CarouselContent>
              {featuredPosts.map((item) => (
                <CarouselItem key={item.data.id}>
                  <Card fullWidth post={item.data} callToAction="Read Now" />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious className="left-9 top-[90%] md:top-1/2 border-white text-white group-hover:opacity-100 lg:opacity-0 transition-opacity duration-300 ease-in-out" />
            <CarouselNext className="right-9 top-[90%] md:top-1/2 border-white text-white group-hover:opacity-100 lg:opacity-0 transition-opacity duration-300 ease-in-out" />
            <CarouselDots className="absolute bottom-4" />
          </Carousel>
        )}

        {remainingPosts.length > 0 && (
          <div className="hidden lg:grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
            {remainingPosts.map((post) => (
              <Card key={post.data.id} post={post.data} callToAction="Read Now" />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default TravelGuideGrid
